import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import i18next from 'i18next';
import DeviceBreakPoints from '../../deviceBreakPoints';
import CustomWorkflowData from './customWorkflowData';
import WorkflowDetailsCard from './WorkflowDetailsCard';
import images from '../../assets/img';
const CustomWorkflow = () => {
  const { isSmallDevice } = DeviceBreakPoints();
  const { design, configure, saveAndOrder, caseStudyDetails } =
    CustomWorkflowData();
  return (
    <Box>
      <Typography variant='h5'>
        {' '}
        {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.OVERVIEW')}
      </Typography>
      <Typography>
        {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.OVERVIEW_DESCRIPTION')}
      </Typography>
      <Box
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Box
          component={'img'}
          src={images.categoryAssets.customWorkflow.overview}
          alt={i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.OVERVIEW_ALT_TEXT')}
          sx={{ maxWidth: '100%', height: 'auto' }}
        />
      </Box>
      <Box>
        <Typography variant='h5'>
          {' '}
          {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.GUIDELINES')}
        </Typography>
        <Typography sx={{ marginBottom: 'revert' }}>
          {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.GUIDELINES_DESCRIPTION')}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant='h6'
          sx={{ color: 'primary.main', marginBottom: '10px' }}
        >
          {' '}
          {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.PREREQUISITES.TITLE')}
        </Typography>
        <Typography
          sx={{ marginBottom: 'revert', fontWeight: 'bold', fontSize: '1rem' }}
        >
          {
            i18next.t(
              'CATEGORY.CUSTOM_WORKFLOW.DETAILS.PREREQUISITES.DETAILS',
              { returnObjects: true }
            )[0].HEADING
          }
        </Typography>
        <Typography sx={{ marginBottom: 'revert' }}>
          {
            i18next.t(
              'CATEGORY.CUSTOM_WORKFLOW.DETAILS.PREREQUISITES.DETAILS',
              { returnObjects: true }
            )[0].DESCRIPTION
          }
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{ marginBottom: 'revert', fontWeight: 'bold', fontSize: '1rem' }}
        >
          {
            i18next.t(
              'CATEGORY.CUSTOM_WORKFLOW.DETAILS.PREREQUISITES.DETAILS',
              { returnObjects: true }
            )[1].HEADING
          }
        </Typography>
        <Typography sx={{ marginBottom: 'revert' }}>
          {
            i18next.t(
              'CATEGORY.CUSTOM_WORKFLOW.DETAILS.PREREQUISITES.DETAILS',
              { returnObjects: true }
            )[1].DESCRIPTION
          }
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{ marginBottom: 'revert', fontWeight: 'bold', fontSize: '1rem' }}
        >
          {
            i18next.t(
              'CATEGORY.CUSTOM_WORKFLOW.DETAILS.PREREQUISITES.DETAILS',
              { returnObjects: true }
            )[2].HEADING
          }
        </Typography>
        <Typography sx={{ marginBottom: 'revert' }}>
          {
            i18next.t(
              'CATEGORY.CUSTOM_WORKFLOW.DETAILS.PREREQUISITES.DETAILS',
              { returnObjects: true }
            )[2].DESCRIPTION
          }
        </Typography>
      </Box>
      <Box
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Typography
          variant='h6'
          sx={{ color: 'primary.main', marginBottom: '10px' }}
        >
          {' '}
          {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.DESIGN.TITLE')}
        </Typography>
        <Grid
          container
          sm={12}
          md={12}
          lg={12}
        >
          {design.map((item) => (
            <Grid
              item
              sm={4}
              md={4}
              lg={4}
              key={item.id}
            >
              <WorkflowDetailsCard
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            </Grid>
          ))}
        </Grid>
        <Box>
          <Typography
            variant='h6'
            sx={{ color: 'primary.main', marginBottom: '10px' }}
          >
            {' '}
            {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.CONFIGURE.TITLE')}
          </Typography>
          <Grid
            container
            sm={12}
            md={12}
            lg={12}
          >
            {configure.map((item) => (
              <Grid
                item
                sm={4}
                md={4}
                lg={4}
                key={item.id}
              >
                <WorkflowDetailsCard
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box>
          <Typography
            variant='h6'
            sx={{ color: 'primary.main', marginBottom: '10px' }}
          >
            {' '}
            {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.SAVE_AND_ORDER.TITLE')}
          </Typography>
          <Grid
            container
            sm={12}
            md={12}
            lg={12}
          >
            {saveAndOrder.map((item) => (
              <Grid
                item
                sm={4}
                md={4}
                lg={4}
                key={item.id}
              >
                <WorkflowDetailsCard
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box>
        <Typography
          variant='h6'
          sx={{
            marginBottom: '10px',
            fontSize: '1.2rem',
            color: 'primary.main'
          }}
        >
          {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.SUPPORT')}
        </Typography>
        <Typography sx={{ marginBottom: 'revert' }}>
          {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.SUPPORT_DESCRIPTION')}
        </Typography>
      </Box>
      <Box>
        <Typography variant='h5'>
          {' '}
          {i18next.t('CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.TITLE')}
        </Typography>
        <Box
          sx={{
            backgroundColor: 'secondary.light'
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '1rem',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '1.5rem'
              }}
            >
              {i18next.t(
                'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.HEADING1'
              )}
            </Typography>
            <Typography
              sx={{
                marginBottom: 'revert',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {i18next.t(
                'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.HEADING1_DESCRIPTION'
              )}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '1rem',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {i18next.t(
                'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.HEADING2'
              )}
            </Typography>
            <Typography sx={{ marginBottom: 'revert', textAlign: 'center' }}>
              {i18next.t(
                'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.HEADING2_DESCRIPTION'
              )}
            </Typography>
          </Box>
          <Box
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Box
              component={'img'}
              src={images.categoryAssets.customWorkflow.caseStudy}
              alt={i18next.t(
                'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.ALT_TEXT'
              )}
              sx={{
                maxWidth: '100%',
                height: 'auto',
                paddingLeft: isSmallDevice ? '0.2rem' : '5rem',
                paddingRight: isSmallDevice ? '0.2rem' : '2rem'
              }}
            />
          </Box>
          {caseStudyDetails.map((item) => (
            <Box key={item.id}>
              <Typography
                variant='h6'
                sx={{
                  marginBottom: '10px',
                  fontSize: '1.2rem',
                  paddingLeft: '1.5rem'
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  marginBottom: 'revert',
                  paddingLeft: '1.5rem',
                  paddingRight: '1.5rem',
                  textAlign: 'justify'
                }}
              >
                {item.description1}
              </Typography>
              {item.description2 && (
                <Typography
                  sx={{
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                    paddingBottom: '20px',
                    textAlign: 'justify'
                  }}
                >
                  {item.description2}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
        >
          <Button
            variant='filledBtn'
            sx={{ padding: '8px 24px' }}
          >
            {i18next.t(
              'CATEGORY.CUSTOM_WORKFLOW.DETAILS.CASE_STUDY.CREATE_CUSTOM_WORKFLOW'
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomWorkflow;
