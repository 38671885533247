import React, { useEffect, Suspense, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './ApplicationInsightsService';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import axios from 'axios';
import IdleTimer from 'react-idle-timer';
import store from './store';
import { Box } from '@mui/material';
import { resetError } from './actions/ErrorAction';
import {
  sessionExpiryLogOut,
  logout,
  regenerateAAATokens,
  regenerateDCSTokens,
  verifyRole,
  getAccessTokenData
} from './actions/LoginAction';
import SnackBar from './components/Shared/Utils/SnackBar';
import * as Constants from './actions/configuration';
import SessionModal from './components/Shared/Utils/Modal/SessionModal';
import { ParentRoutes } from './routes/router';
import { getCountryList } from './actions/CountryListAction';
import ConfirmModal from './components/Shared/Utils/Modal/ConfirmModal';
import { CookieConsent } from '@mobilitystore/cookie-consent-interface';
import PropTypes from 'prop-types';
// import reactGA from 'react-ga';
import RouteChangeTracker from './routes/RouteChangeTracker';
import idConstants from './data/idConstants';
import { portalViewData } from './data/appConstants';
import { setPortalView } from './actions/LandingAction';
import i18next from 'i18next';
import IEAlert from './components/IEAlert';
import getMetaDataDetails from './utils/getMetaDataDetails';
import { setCookiePolicy, unSetCookiePolicy, getProductId } from './utils';
import DeviceBreakPoints from './deviceBreakPoints';
import Loader from './components/Shared/Utils/Loader';

const AppMainComponent = (props) => {
  const appInsights = useAppInsightsContext();
  const timeout = 1000 * 1800 * 1;
  const [showModal, setShowModal] = useState(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [cookieConsentError, setCookieConsentError] = useState(false);
  const [cookieConsentErrorMessage, setCookieConsentErrorMessage] =
    useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openCookieModal, setOpenCookieModal] = useState(true);
  const [openIEDialog, setOpenIEDialog] = useState(false);

  const idToken = localStorage.getItem(idConstants.tokens.aaaIdToken);
  const refreshToken = localStorage.getItem(idConstants.tokens.aaaRefreshToken);
  let idleTimer = null;
  const docHubToken = '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';
  const confirmModalText =
    process.env.REACT_APP_AAA_CLIENT_ID === Constants.TENANT.TOS
      ? i18next.t('SNACKBAR.CANNOT_PLACE_ORDER_BEST')
      : i18next.t('SNACKBAR.CANNOT_PLACE_ORDER');

  useEffect(() => {
    if (idToken) {
      props.getAccessTokenData(idToken);
      store.dispatch({
        type: 'ACCESS_TOKEN',
        payload: idToken
      });
    }

    if (refreshToken) {
      store.dispatch({
        type: 'REFRESH_TOKEN',
        payload: refreshToken
      });
    }
  }, []);

  useEffect(() => {
    let retryCount = 0;
    let apiRequestArray = [];

    axios.interceptors.request.use(
      (config) => {
        let token = '';
        if (config.url.includes(Constants.TOOLS.DOCHUB_DEPLOYMENT)) {
          token = docHubToken;
        } else {
          token = localStorage.getItem(idConstants.tokens.aaaIdToken);
        }
        if (token) {
          config.headers.Authorization = 'bearer ' + token;
        }
        if (
          config.url?.includes(
            `${Constants.BASE_URL}/${Constants.USER_SERVICE}`
          ) ||
          config.url?.includes(
            `${Constants.BASE_URL}/${Constants.RATING_SERVICE}`
          ) ||
          config.url?.includes(
            `${Constants.BASE_URL}/${Constants.ORDER_SERVICE}`
          ) ||
          config.url?.includes(
            `${Constants.BASE_URL}/${Constants.PRODUCT_SEARCH}`
          ) ||
          config.url?.includes(
            `${Constants.BASE_URL}/${Constants.CART_SERVICE}`
          )
        ) {
          config.headers.common['X-tenant'] = 'tos';
        }
        if (
          config.url?.includes(
            `${Constants.BASE_URL}/${Constants.PRODUCT_SERVICE}/`
          ) ||
          config.url?.includes(
            `${Constants.BASE_URL}/${Constants.DOCUMENT_SERVICE}`
          ) ||
          config.url?.includes(
            `${Constants.BASE_URL}/${Constants.PRICING_SERVICE}`
          )
        ) {
          config.headers.common['X-tenant'] = 'mkosint';
        }
        if (
          config.url?.includes(
            `${Constants.BASE_URL}/${Constants.ORDER_FULFILLMENT_SERVICE}`
          )
        ) {
          config.headers.common['X-tenant'] = 'mkos';
        }
        return config;
      },
      (error) => {
        appInsights.trackException(error);
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        apiRequestArray = [];
        return response;
      },
      function (error) {
        const originalRequest = error.config;

        if (
          error.response !== undefined &&
          error.response.status === 400 &&
          originalRequest.url === Constants.AUTHENTICATION_URL
        ) {
          props.logout();
          return Promise.reject(error);
        }

        // refresh token flow
        if (
          (error.message === idConstants.errorCodes.networkError ||
            error.response === undefined ||
            error.response.status === 401) &&
          !originalRequest._retry
        ) {
          setIsNetworkError(false);
          if (retryCount < 5) {
            retryCount++;
            if (apiRequestArray.length === 0) {
              apiRequestArray.push(originalRequest);
              return props.regenerateAAATokens()?.then(() => {
                return axios(originalRequest);
              });
            } else {
              return axios(originalRequest);
            }
          } else {
            retryCount = 0;
            if (idToken && localStorage.getItem('popUpOpen') !== '1') {
              setOpenConfirmModal(true);
            }
            if (error.message === idConstants.errorCodes.networkError) {
              setIsNetworkError(true);
            }
            return Promise.reject(error);
          }
        }

        return Promise.reject(error);
      }.bind(this)
    );

    if (Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false') {
      const originalFetch = window.fetch;
      window.fetch = function (...args) {
        return originalFetch.apply(this, arguments).then((response) => {
          if (
            response?.status === 401 &&
            localStorage.dcsCode &&
            !args[1].headers.hasOwnProperty('mss-api-access-token')
          ) {
            return props.regenerateDCSTokens(localStorage.dcsCode)?.then(() => {
              arguments[1].headers['Authorization'] =
                'bearer ' + localStorage.dcsJwtToken;
              return originalFetch(arguments[0], {
                ...arguments[1]
              });
            });
          }
          return response;
        });
      };
    }
  }, []);

  useEffect(() => {
    if (getCookie('cookieConsentByUser') !== -1) {
      setOpenCookieModal(false);
      if (getCookie('cookieConsentByUser') === '1') {
        // Initializing Google Analytics
        // reactGA.initialize(Constants.GOOGLE_ANALYTICS_TRACKING_ID);
        // reactGA.pageview(window.location.pathname);
      }
    }
    const isIE =
      window.navigator.userAgent.indexOf('MSIE') > -1 ||
      window.navigator.userAgent.indexOf('Trident') > -1;
    if (isIE) {
      setOpenIEDialog(true);
    }
    if (!document.querySelector("link[rel='canonical']")) {
      let canonicalLink = document.createElement('link');
      canonicalLink.rel = 'canonical';
      canonicalLink.href = window.location.href;
      document.getElementsByTagName('head')[0].appendChild(canonicalLink);
    }
    const productIdRegex = new RegExp(/(?=.*[a-zA-Z])(?=.*[0 - 9])/);
    const [metaTitle, metaDescription] = getMetaDataDetails(
      window.location.pathname
    );
    if (!productIdRegex.test(getProductId()) && metaTitle && metaDescription) {
      const metaDataConstants = idConstants?.metaData;
      document
        .getElementsByTagName(metaDataConstants.metaTag)
        .namedItem(metaDataConstants.title)
        .setAttribute(metaDataConstants.content, i18next.t(metaTitle));
      document
        .getElementsByTagName(metaDataConstants.metaTag)
        .namedItem(metaDataConstants.description)
        .setAttribute(metaDataConstants.content, i18next.t(metaDescription));
      document.title = i18next.t(metaTitle);
    }
  }, []);
  useEffect(() => {
    props.getCountryList();
  }, []);

  const cookieConsentResponseHandler = (response) => {
    if (response.cookies.marketing) {
      setCookiePolicy(response.expiryTime);
    } else {
      unSetCookiePolicy(response.expiryTime);
    }
  };

  const cookieConsentErrorHandler = (response) => {
    setCookieConsentError(true);
    setCookieConsentErrorMessage(response);
  };

  const getCookie = (cookieName) => {
    const getCookies = decodeURIComponent(document.cookie).split(';');
    for (const [index, value] of getCookies.entries()) {
      if (getCookies[index].split('=')[0].indexOf(cookieName) !== -1) {
        return value.split('=')[1];
      }
    }
    return -1;
  };

  const onIdle = () => {
    idleTimer?.reset();
    if (localStorage.getItem(idConstants.tokens.aaaIdToken)) {
      setShowModal(true);
    }
  };

  const handleLogout = () => {
    setShowModal(false);
    props.sessionExpiryLogOut(props?.location.pathname);
  };

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(false);
    store.dispatch({
      type: 'CONFIRM_MODAL',
      payload: false
    });
  };

  const okBtnAction = () => {
    setOpenIEDialog(false);
  };

  const toggledIf =
    Constants.SYSTEM_ACCOUNT_AUTH_TOGGLE === 'false'
      ? openCookieModal &&
        window.location.pathname !== '/corporate-information' &&
        window.location.pathname !== '/legal-notice' &&
        window.location.pathname !== '/data-protection-policy' &&
        window.location.pathname !== '/terms-of-use' &&
        window.location.pathname !==
          '/' + Constants.CALLBACK_PARAM.AAA_CALLBACK &&
        window.location.pathname !==
          '/' + Constants.CALLBACK_PARAM.DCS_CALLBACK &&
        window.location.pathname !==
          '/' + Constants.CALLBACK_PARAM.SWITCH_CALLBACK &&
        window.location.pathname !==
          '/' + Constants.CALLBACK_PARAM.DEREGISTRATION_CALLBACK &&
        window.location.pathname !==
          '/' + Constants.CALLBACK_PARAM.FEDERATED_CALLBACK
      : openCookieModal &&
        window.location.pathname !== '/corporate-information' &&
        window.location.pathname !== '/legal-notice' &&
        window.location.pathname !== '/data-protection-policy' &&
        window.location.pathname !== '/terms-of-use' &&
        window.location.pathname !==
          '/' + Constants.CALLBACK_PARAM.AAA_CALLBACK &&
        window.location.pathname !==
          '/' + Constants.CALLBACK_PARAM.SWITCH_CALLBACK &&
        window.location.pathname !==
          '/' + Constants.CALLBACK_PARAM.DEREGISTRATION_CALLBACK &&
        window.location.pathname !==
          '/' + Constants.CALLBACK_PARAM.FEDERATED_CALLBACK;

  return (
    <>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={timeout}
      />
      {props?.error &&
        props?.error?.data &&
        props?.error?.data?.status !== 401 && (
          <SnackBar
            severity='error'
            changeState={() => props.resetError()}
            message={props?.error?.data?.message}
          />
        )}
      {isNetworkError && props?.accessToken ? (
        <SnackBar
          severity='error'
          changeState={() => props.resetError()}
          message={i18next.t('TOAST_MESSAGES.ERROR')}
        />
      ) : (
        ''
      )}
      {props?.error !== null &&
        props.accessToken &&
        props?.error !== undefined &&
        props?.error?.data === undefined &&
        props?.error?.message !== idConstants.errorCodes.networkError &&
        props?.error?.response?.status !== 401 && (
          <SnackBar
            severity='error'
            changeState={() => props.resetError()}
            message={
              props?.error ===
                i18next.t('TOAST_MESSAGES.SAVE_COMPANY_DETAILS_ERR.MSG') ||
              props?.error ===
                i18next.t('TOAST_MESSAGES.SAVE_USER_DETAILS_ERR.MSG')
                ? i18next.t('TOAST_MESSAGES.INCORRECT_DETAILS_ERR.MSG')
                : i18next.t('TOAST_MESSAGES.ERROR')
            }
          />
        )}
      <Suspense fallback={<Loader />}>
        <Box className={'app' + props?.screenType}>
          <ParentRoutes />
        </Box>
        {showModal && (
          <SessionModal
            heading={i18next.t('SNACKBAR.SESSION_EXPIRED') + '!'}
            modalContent={i18next.t('SNACKBAR.SESSION_EXPIRED_MSG')}
            okOperation={handleLogout}
          />
        )}
      </Suspense>
      {(openConfirmModal || props?.confirmModal) &&
        props?.userCompanyStatus?.companyRegisteredStatus !== 'approved' && (
          <ConfirmModal
            modalContent=''
            heading={confirmModalText}
            category={'approve'}
            callback={handleOpenConfirmModal}
            okOperation={handleOpenConfirmModal}
            cancelBtn='disable'
            {...props}
          />
        )}
      {toggledIf && (
        <CookieConsent
          dataProtectionPolicy='/data-protection-policy'
          corporateInformation='/corporate-information'
          open={openCookieModal}
          responseHandler={cookieConsentResponseHandler}
          errorHandler={cookieConsentErrorHandler}
        />
      )}
      {openIEDialog && <IEAlert okOperation={okBtnAction}></IEAlert>}
      {cookieConsentError && (
        <SnackBar
          severity='error'
          changeState={() => props.resetError()}
          message={cookieConsentErrorMessage}
        />
      )}
    </>
  );
};

const mapStateToProps = (redux_state) => ({
  error: redux_state.ErrorReducer.error,
  errorLogin: redux_state.loginReducer.errorLogin,
  userCompanyStatus: redux_state.CompanyDetailsReducer.userCompanyStatus,
  accessToken: redux_state.loginReducer.accessToken,
  confirmModal: redux_state.loginReducer.confirmModal
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetError,
      sessionExpiryLogOut,
      logout,
      regenerateAAATokens,
      regenerateDCSTokens,
      verifyRole,
      getCountryList,
      setPortalView,
      getAccessTokenData
    },
    dispatch
  );
}

const AppMain = (props) => {
  const { isSmallDevice, smScreen, mdScreen } = DeviceBreakPoints();
  let screenType = '';
  if (mdScreen) {
    screenType = ' md-screen';
  } else if (smScreen) {
    screenType = ' sm-screen';
  } else if (isSmallDevice) {
    screenType = ' xs-screen';
  }

  useEffect(() => {
    if (mdScreen) {
      props.setPortalView(portalViewData.DESKTOP);
    } else if (smScreen) {
      props.setPortalView(portalViewData.TAB);
    } else if (isSmallDevice) {
      props.setPortalView(portalViewData.MOBILE);
    }
  }, [screenType]);

  return (
    <>
      <AppMainComponent
        {...props}
        screenType={screenType}
      />
      <RouteChangeTracker />
    </>
  );
};

AppMain.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withAITracking(reactPlugin, AppMain)));
